/* PC START */

.yellow-point-m {
    width: 1.111vw;
    height: 1.111vw;
    background: #FFEC5F 0% 0% no-repeat padding-box;
    border-radius: 50%;
    min-width: 1.111vw;
}

.yellow-point-s {
    width: 0.417vw;
    height: 0.417vw;
    background: #FFEC5F 0% 0% no-repeat padding-box;
    border-radius: 50%;
    min-width: 0.417vw;
}

.part-1 {
    margin-top: 5.625vw;
    margin-bottom: 7.083vw;
    text-align: center;
    line-height: normal;
    color: #333;

    .subject-en {
        font-size: 1.667vw;
        letter-spacing: 0.083vw;
        margin-bottom: 0.764vw;
    }

    .subject {
        font-size: 2.5vw;
        letter-spacing: 0.125vw;
        margin-bottom: 1.944vw;
    }

    .subtitle {
        font-size: 1.111vw;
        letter-spacing: 0.056vw;
    }
}

.part-2 {
    background-image: url('../assets/images/common/bg_grey@2x.png');
    padding: 8.333vw 6.944vw 11.111vw 6.944vw;
    color: #333;

    .card {
        padding: 5.764vw 5.556vw 5.556vw 5.556vw;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 10px #3333331A;
        line-height: normal;

        .head-text-wrap {
            align-items: center;
        }

        .point1-title {
            font-size: 2.222vw;
            letter-spacing: 0.111vw;
            margin-left: 0.833vw;
        }

        .point1-text {
            font-size: 1.111vw;
            letter-spacing: 0.056vw;
            margin-top: 1.528vw;
            margin-bottom: 1.528vw;
        }

        .point2-title {
            font-size: 1.667vw;
            letter-spacing: 0.083vw;
            margin-left: 0.556vw;
        }

        .point2-text {
            font-size: 1.111vw;
            letter-spacing: 0.056vw;
            margin-top: 1.319vw;
            margin-bottom: 1.528vw;
        }

        .point3-title {
            font-size: 1.389vw;
            letter-spacing: 0.069vw;
            margin-left: 0.556vw;
        }

        .point3-text {
            font-size: 1.111vw;
            letter-spacing: 0.056vw;
            margin-top: 1.042vw;
            margin-bottom: 1.528vw;
        }

        .image1 {
            width: 72.222vw;
            height: 33.333vw;
            margin-bottom: 5.556vw;
            object-fit: contain;
        }

        .highlights-area {
            background-image: url('../assets/images/common/bg_grey@2x.png');
            padding: 2.431vw;
            margin-bottom: 5.903vw;
        }

        .highlights-text {
            font-size: 1.042vw;
            letter-spacing: 0.052vw;
            color: #666666;
        }

        .numeric-area {
            margin-bottom: 3.889vw;

            .numeric-text-wrap {
                align-items: center;
                margin-bottom: 1.736vw;

                .yellow-point-s {
                    margin-right: 0.556vw;
                }

                .number {
                    font-size: 1.25vw;
                    letter-spacing: 0.1vw;
                    margin-right: 1.111vw;
                }

                .numeric-text {
                    font-size: 1.111vw;
                    letter-spacing: 0.056vw;
                }
            }
            
        }

        .list-area {
            margin-bottom: 3.611vw;

            .list-wrap {
                align-items: center;
                margin-bottom: 1.806vw;

                &:last-child {
                    margin-bottom: 0;
                }

                .yellow-point-s {
                    margin-right: 0.833vw;
                }

                .list-text {
                    font-size: 1.111vw;
                    letter-spacing: 0.056vw;
                }
            }
        }

        .image-double .image2 {
            width: 34.722vw;
            height: 22.222vw;
            margin-right: 2.778vw;

            .image3 {
                width: 34.722vw;
                height: 22.222vw;
            }
        }
    }
}


.part-3 {
    padding: 11.111vw 8.333vw;
    color: #333;

    .other-en {
        font-size: 0.972vw;
        letter-spacing: 0.097vw;
        margin-bottom: 0.972vw;
    }

    .other-service {
        font-size: 2.5vw;
        letter-spacing: 0.125vw;
        margin-bottom: 4.028vw;
    }

    .card {
        width: 26.389vw;
        box-shadow: 0px 0px 10px #3333331A;
        border-radius: 12px;
        margin-right: 2.083vw;
        padding: 2.708vw 2.083vw;
        text-align: center;
        line-height: normal;

        .card-title {
            font-size: 1.528vw;
            letter-spacing: 0.076vw;
            font-weight: bold;
            margin-bottom: 1.597vw;
            min-height: 4.583vw;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20%;
        }

        .card-text {
            font-size: 1.042vw;
            letter-spacing: 0.052vw;
            margin-bottom: 2.083vw;
            text-align: left;
            height: 60%;
        }

        .card-action {
            height: 20%;
        }

        img {
            width: 2.083vw;
            height: 2.083vw;
            margin-left: 0.556vw;
        }
    }
}

/* PC END */

/* SP START */

@media (max-width: 768px) {
    .yellow-point-m {
        width: 2.933vw;
        height: 2.933vw;
        min-width: 2.933vw;
    }
    .yellow-point-s {
        width: 1.6vw;
        height: 1.6vw;
        min-width: 1.6vw;
    }
    .part-1 {
        margin-top: 13.333vw;
        margin-bottom: 24vw;
        align-items: center;
        .subject-en {
            font-size: 4.8vw;
            letter-spacing: 0.384vw;
            margin-bottom: 1.333vw;
            max-width: 78.667vw;
        }
        .subject {
            font-size: 8vw;
            letter-spacing: 0.64vw;
            margin-bottom: 5.333vw;
            max-width: 89.333vw;
        }
        .subtitle {
            font-size: 3.733vw;
            letter-spacing: 0.187vw;
            max-width: 89.333vw;
        }
    }
    .part-2 {
        padding: 18.667vw 5.333vw 21.333vw 5.333vw;
        .card {
            padding: 14vw 5.333vw 13.333vw 5.333vw;
            .head-text-wrap .align-self-1 {
                align-self: start;
                margin-top: 3.571vw;
                .align-self-2 {
                    align-self: start;
                    margin-top: 2.714vw;
                }
                .align-self-3 {
                    align-self: start;
                    margin-top: 2.286vw;
                }
            }
            .point1-title {
                font-size: 6.4vw;
                letter-spacing: 0.32vw;
                margin-left: 2.133vw;
                font-weight: bold;
            }
            .point1-text {
                font-size: 3.733vw;
                letter-spacing: 0.149vw;
                margin-top: 2.933vw;
                margin-bottom: 3.733vw;
                line-height: 6.461vw;
            }
            .point2-title {
                font-size: 5.333vw;
                letter-spacing: 0.267vw;
                margin-left: 2.133vw;
                font-weight: bold;
            }
            .point2-text {
                font-size: 3.733vw;
                letter-spacing: 0.149vw;
                margin-top: 2.933vw;
                margin-bottom: 3.733vw;
                line-height: 6.461vw;
            }
            .point3-title {
                font-size: 4.8vw;
                letter-spacing: 0.24vw;
                margin-left: 2.133vw;
                font-weight: bold;
            }
            .point3-text {
                font-size: 3.733vw;
                letter-spacing: 0.149vw;
                margin-top: 2.933vw;
                margin-bottom: 3.733vw;
                line-height: 6.461vw;
            }
            .image1 {
                width: 78.667vw;
                height: 36.267vw;
                margin-bottom: 13.333vw;
            }
            .highlights-area {
                padding: 5.333vw;
                margin-bottom: 13.333vw;
            }
            .highlights-text {
                font-size: 3.733vw;
                letter-spacing: 0.149vw;
                line-height: 6.461vw;
            }
            .numeric-area {
                margin-bottom: 13.333vw;
                .numeric-text-wrap {
                    margin-bottom: 3.733vw;
                }
                .numeric-text-wrap .yellow-point-s {
                    margin-right: 1.333vw;
                    align-self: start;
                    margin-top: 2.429vw;
                }
                .numeric-text-wrap .number {
                    font-size: 4.267vw;
                    letter-spacing: 0.341vw;
                    margin-right: 2.667vw;
                    align-self: start;
                    line-height: 6.461vw;
                }
                .numeric-text-wrap .numeric-text {
                    font-size: 3.733vw;
                    letter-spacing: 0.187vw;
                    line-height: 6.461vw;
                }
            }
            .list-area {
                margin-bottom: 13.333vw;
                .list-wrap {
                    margin-bottom: 3.733vw;
                    .yellow-point-s {
                        margin-right: 1.333vw;
                        align-self: start;
                        margin-top: 2.5vw;
                    }
                    .list-text {
                        font-size: 3.733vw;
                        letter-spacing: 0.187vw;
                        line-height: 6.461vw;
                    }
                }
            }
            .image-double .image2 {
                width: 37.333vw;
                height: 25.333vw;
                margin-right: 4vw;
                .image3 {
                    width: 37.333vw;
                    height: 25.333vw;
                }
            }
        }
    }
    .part-3 {
        padding: 21.333vw 5.333vw 16vw 5.333vw;
        .other-en {
            font-size: 2.667vw;
            letter-spacing: 0.267vw;
            margin-bottom: 3.333vw;
        }
        .other-service {
            font-size: 6.4vw;
            letter-spacing: 0.32vw;
            margin-bottom: 7.733vw;
        }
        .other-text {
            font-size: 3.733vw;
            letter-spacing: 0.187vw;
            margin-bottom: 10.667vw;
            line-height: 6.461vw;
        }
        .card {
            width: 89.333vw;
            box-shadow: 0px 0px 20px #3333331A;
            border-radius: 20px;
            margin-right: 0;
            padding: 7.2vw 5.333vw;
            line-height: 6.461vw;
            margin-bottom: 8vw;
            .card-title {
                font-size: 4.8vw;
                letter-spacing: 0.24vw;
                margin-bottom: 4.267vw;
                min-height: 14.4vw;
                height: auto;
            }
            .card-text {
                font-size: 3.733vw;
                letter-spacing: 0.187vw;
                margin-bottom: 4.533vw;
                height: auto;
            }
            .card-action {
                height: auto;
                div {
                    font-size: 3.733vw;
                    letter-spacing: 0.187vw;
                    font-weight: 500;
                }
                img {
                    width: 8vw;
                    height: 8vw;
                    margin-left: 2.667vw;
                }
            }
        }
    }
}

/* SP END */